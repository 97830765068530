<template>
  <div>
      我是首页
  </div>
</template>
<script>


export default {

}
</script>

<style lang='less' scoped>
</style>
